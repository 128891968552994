<template>
  <b-field
    :label="label"
  >
    <b-datepicker
      :value="computedValue"
      :locale="user.locale"
      expanded
      @input="updateDate"
    />
    <b-button
      icon-left="close"
      type="is-default"
      @click="remove"
    />
  </b-field>
</template>

<script>
import Mixin from './Mixin'
import * as firebase from 'firebase'

export default {
  mixins: [Mixin],
  computed: {
    computedValue() {
      if(this.value) {
        if(_.isFunction(this.value.toDate)) {
          const functionValue = this.value.toDate()
          // console.log({ functionValue })
          return functionValue
        }
        // console.log('this.value', this.value)
        return this.value
      }
      // console.log('date value null')
      return null
    }
  },
  methods: {
    updateDate(value) {
      this.update(firebase.firestore.Timestamp.fromDate(value))
    },
    remove() {
      this.update(null)
    }
  }
  /* methods: {
    change (value) {
      this.$parent.$parent.$emit('change', value ? moment(value).format('YYYY-MM-DD') : null)// HH:mm:ss'))
      this.$parent.close()
    }
  } */
}
</script>
