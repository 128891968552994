<template>
  <div class="columns">
    <div class="column">
      <a-select
        :manual-doc="userDoc"
        field="routes.failureParts.where.failure.operator"
        :label="$t('operators.sg')"
        options="operators"
        null-option
        @change="(value) => updateAndFetch('failure.operator', value, true)"
      />

      <a-select
        :manual-doc="userDoc"
        field="routes.failureParts.where.part.supplier"
        :label="$t('suppliers.ref.name')"
        options="suppliers"
        null-option
        @change="(value) => updateAndFetch('part.supplier', value, true)"
      />

      <a-select
        :manual-doc="userDoc"
        field="routes.failureParts.where.part"
        :label="$t('parts.ref.name')"
        :options="supplierParts"
        null-option
        @change="(value) => updateAndFetch('part', value, true)"
      />

      <a-select
        :manual-doc="userDoc"
        field="routes.failureParts.where.failure.type"
        :label="$t('failures.ref.type')"
        :options="keys(failureTypes)"
        :labels="failureTypes"
        null-option
        @change="(value) => updateAndFetch('failure.type', value, true)"
      />

      <simple-taginput
        :manual-doc="userDoc"
        field="routes.failureParts.where.state"
        :label="$t('common.state')"
        :options="$store.getters['failureParts/fields'].state.options"
        labels="failureParts.options.state"
        null-option
        @change="(value) => updateAndFetch('state', value, true)"
      />

      <a-simple-datepicker
        :manual-doc="userDoc"
        field="routes.failureParts.where.failure.progress.reported.at_gte"
        :label="$t('common.from')"
        @change="(value) => updateAndFetch('failure.progress.reported.at_gte', value, true)"
      />
    </div>

    <div class="column">
      <a-input
        :manual-doc="userDoc"
        field="routes.failureParts.where.number_array"
        :label="$t('common.number')"
        update-on-click
        @change="updateNumber"
      />
    </div>
  </div>
</template>

<script>
import { keys, startsWith } from 'lodash'
import { mapGetters, mapActions } from 'vuex'
import SimpleTaginput from '@/components/SimpleTaginput'
import ASimpleDatepicker from '@/components/Form/ASimpleDatepicker'


export default {
  components: {
    SimpleTaginput,
    ASimpleDatepicker
  },
  computed: {
    ...mapGetters({
      parts: 'parts/items',
      suppliers: 'suppliers/items'
    }),
    process() {
      return process
    },
    userDoc () {
      return { module: 'user' }
    },
    where () {
      return this.user.routes.failureParts.where
    },
    supplierParts() {
      if(this.where && this.where.part && this.where.part.supplier && this.where.part.supplier.id) {
        return this.parts.filter(item => item.supplier && item.supplier.id && (item.supplier.id === this.where.part.supplier.id))
      }
      return this.parts
    },
    failureTypes() {
      return {
        'guarantee': this.$t('failures.options.type.guarantee'),
        'post guarantee': this.$t('failures.options.type.post_guarantee')
      }
    }
  },
  created () {
    this.$store.dispatch('parts/fetchAll')
    window.filters = this
    /*if (this.user.role == 'customer' && (this.where.failure.customer.id != this.user.customer.id)) {
      this.updateCustomer(this.user.customer)
    } else {
      if (this.where.failure.customer) {
        this.$store.dispatch('vehicles/fetchAndAdd', { where: [['customer.id', '==', this.where.failure.customer.id]],
          limit: 0 })
      }
    }*/
  },
  methods: {
    keys,
    async updateAndFetch (prop, value, fetch = false) {
      if(this.where.number_array) {
        await this.$store.dispatch('user/update', {
          path: `routes.failureParts.where.number_array`,
          value: null
        })
      }

      if(startsWith(prop,'part')) {
        await this.$store.dispatch('user/update', {
          path: `routes.failureParts.where.part`,
          value: null
        })
      }

      await this.$store.dispatch('user/update', {
        path: `routes.failureParts.where.${prop}`,
        value
      })
      if(fetch) {
        this.$store.dispatch('failureParts/fetchDocs')
      }
    },
    async updateNumber (value) {
      await this.$store.dispatch('failureParts/resetFilters')
      this.updateAndFetch('number_array', value, true)
    }
  }
}
</script>
